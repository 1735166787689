import React from 'react'
import Title from '../Title/Title'
import Main from '../Main/Main'
import Biography from '../Biography/Biography'
import Nav from '../Nav/Nav'
import Instagram from '../Instagram/Instagram'

const Home = props => {
  return (
    <React.Fragment>
      <Nav />
      <Title />
      <Instagram />
      <Main />
      <Biography />
    </React.Fragment>
  )
}

export default Home
