import React from 'react'
import Nav from '../Nav/Nav'

const Media = props => {
    return (
        <React.Fragment>
            <Nav />
        </React.Fragment>
    )
}

export default Media