import { Fragment } from 'react'

const Container = props => {
  return (
    <Fragment>
      <div className='container'>
        <div className='centered-picture'>
          <video className='video' width='700' height='500' controls>
            <source
              src='https://dandals-videos.s3.us-east-2.amazonaws.com/Introducing+the+Dandals+short+form+to+the+top.mp4'
              type='video/mp4'
            />
          </video>
        </div>
      </div>
    </Fragment>
  )
}

export default Container
