import { Fragment } from 'react'
import './Instagram.css'
import instagramLogo from '../../images/Instagram_logo_2016.svg'

const Instagram = props => {
  return (
    <Fragment>
      <div className='instagram'>Join us on Instagram</div>
      <a href='https://www.instagram.com/thedandals/' target='_blank' rel="noreferrer">
        <img
          className='instagram-logo'
          src={instagramLogo}
          alt='instagram logo'
        ></img>
      </a>
    </Fragment>
  )
}

export default Instagram
