import { Fragment } from 'react'
import './Title.css'

const Title = props => {
  return (
    <Fragment>
      <div className='title'>It's The Dandals!!</div>
    </Fragment>
  )
}

export default Title
