import React from 'react'
import Nav from '../Nav/Nav'
import dandalsTshirt from '../../images/dandalsTshirt.png'
import './Live.css'

const Live = props => {
  return (
    <React.Fragment>
      <Nav />
      <div className='live-container'>
        <div className='live-picture'>
          <img
            className='dandals-polaroid'
            src={dandalsTshirt}
            alt='grad-party-polaroid'
          />
        </div>
        <div className='live-details'>
          <div className='gig'>
            {/* <div className='gig-header'>Greg's Basement - 9pm 12/31/2022</div> */}
            {/* <div className='gig-details'>We'll be playing in a great venue near you shortly, check back soon!</div> */}
          </div>
          <div className='gig'>
            <div className='gig-header'>4th Annual Sternstock Music Festival  - Sunday May 26th 2024</div>
            <br></br>
            <div className='gig-details'>Join us for music featuring seven acts including the Dandals! Festival starts at 1pm, music at 2pm until 10pm</div>
            <div className='gig-details'>Details and RSVP <a href='http://evite.me/fnHcDbTDmw' target="_blank">Here</a></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Live
