import { Fragment } from 'react'
import './Biography.css'

const Biography = props => {
  return (
    <Fragment>
      <div className='bio-container'>
        <div className='bio-header'>
          We're The Dandals and we'd like to rock with you!
        </div>
        <div className='bio-detail'>
          A group of lifelong musicians and dads, we play anything and
          everything from surf rock to classic country to 80's pop to 60's rock.
          We have a wide range of playing experience and backgrounds, and love
          to play music and have music nerd debates about what time signature a
          song is in.
        </div>
      </div>
    </Fragment>
  )
}

export default Biography
